












































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      self: this,
      loading: false,
      setting: null,
      barcodeScanning: false,
      barcode: "",
      barcodeResult: null,
      actionLoading: false,
      actionSuccess: false,
      actionError: null,
      receivedAllConfirmed: false,
    };
  },
  computed: {
    actionable() {
      if (this.barcodeResult?.item) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async scanBarcode() {
      if (!this.barcode) {
        return;
      }
      this.receivedAllConfirmed = false;
      this.barcodeScanning = true;
      try {
        const item = await coreApiClient.callRaw("trackingNumberScanItems", "prepareCreate", {
          transportTrackingNumber: this.barcode,
        });
        this.barcodeResult = {
          barcode: this.barcode,
          item,
        };
        this.actionSuccess = false;
        this.actionError = null;
        this.receivedAllConfirmed = true;
      } catch (e) {
        this.barcodeResult = {
          barcode: this.barcode,
        };
        this.actionError = e;
      } finally {
        this.barcodeScanning = false;
        this.reset();
      }
    },
    async action() {
      const barcode = this.barcodeResult?.barcode;
      if (!barcode) {
        return;
      }
      this.actionLoading = true;
      try {
        await coreApiClient.callRaw("trackingNumberScanItems", "create", undefined, this.barcodeResult.item);
        this.actionSuccess = true;
        this.actionError = null;
      } catch (e) {
        this.actionSuccess = false;
        this.actionError = e;
      } finally {
        this.actionLoading = false;
      }
    },
    async actionWithReceiveAll() {
      const barcode = this.barcodeResult?.barcode;
      if (!barcode) {
        return;
      }
      for (const productItem of this.barcodeResult?.item?.productItems) {
        productItem.realCount = productItem.count;
      }
      this.actionLoading = true;
      try {
        await coreApiClient.callRaw("trackingNumberScanItems", "create", undefined, this.barcodeResult.item);
        this.actionSuccess = true;
        this.actionError = null;
      } catch (e) {
        this.actionSuccess = false;
        this.actionError = e;
      } finally {
        this.actionLoading = false;
      }
    },
    async reset() {
      setTimeout(() => {
        this.barcode = "";
        this.$refs.barcodeInput?.focus();
      });
    },
  },
  watch: {
    receivedAllConfirmed(value, previousValue) {
      if (!!value === !!previousValue) {
        return;
      }
      for (const productItem of this.barcodeResult?.item?.productItems) {
        productItem.realCount = value ? productItem.count : 0;
        productItem.failedCount = 0;
        productItem.switchedCount = 0;
      }
    },
  },
  async created() {
    this.reset();
  },
});
